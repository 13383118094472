var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.TITLE'),"header-content":_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.DESC')}}),_vm._v(" "),_c('form',{staticClass:"mx-0 flex flex-wrap",on:{"submit":function($event){$event.preventDefault();return _vm.createChannel()}}},[_c('div',{staticClass:"w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"},[_c('label',[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.LABEL'))+"\n              "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inboxCategory),expression:"inboxCategory"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inboxCategory=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","hidden":""}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.PLACEHOLDER'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp_sales'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP_SALES'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'city'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.CITY'
                      ))+"\n                  ")])])])]),_vm._v(" "),_c('div',{staticClass:"w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"},[_c('label',{class:{ error: _vm.$v.channelName.$error }},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL'))+"\n        "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelName),expression:"channelName",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')},domProps:{"value":(_vm.channelName)},on:{"blur":[_vm.$v.channelName.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.channelName=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.channelName.$error)?_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"},[_c('label',{class:{ error: _vm.$v.webhookUrl.$error }},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.LABEL'))+"\n        "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.webhookUrl),expression:"webhookUrl",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.PLACEHOLDER')},domProps:{"value":(_vm.webhookUrl)},on:{"blur":[_vm.$v.webhookUrl.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.webhookUrl=$event.target.value.trim()}}})]),_vm._v(" "),_c('p',{staticClass:"help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.SUBTITLE'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"w-full"},[_c('woot-submit-button',{attrs:{"loading":_vm.uiFlags.isCreating,"button-text":_vm.$t('INBOX_MGMT.ADD.API_CHANNEL.SUBMIT_BUTTON')}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }