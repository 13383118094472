<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('NEW_CONVERSATION.TITLE_TRANSFER')"
        :header-content="$t('NEW_CONVERSATION.DESC_OTHER')"
      />
      <conversation-form
        :contact="contact"
        :on-submit="onSubmit"
        :transfer="true"
        :inbox-id="inboxId"
        @success="onSuccess"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import ConversationForm from './ConversationForm';

export default {
  components: {
    ConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxType: {
      type: String,
      default: '',
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  watch: {
    'contact.id'(id) {
      this.$store.dispatch('contacts/fetchContactableInbox', id);
    },
  },
  async mounted() {
    const { id } = this.contact;
    await this.$store.dispatch('contacts/fetchContactableInbox', id);
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async onSubmit(contactItem) {
      let resp = '';
      if (this.inboxType === 'Channel::WebWidget') {
        contactItem.oldConversationId = this.conversationId;
        contactItem.inboxType = this.inboxType;
        resp = await this.$store.dispatch(
          'contactConversations/transfer_conversation',
          contactItem
        );
      } else {
        contactItem.duplicated = true;
        contactItem.oldConversationId = this.conversationId;
        contactItem.inboxType = this.inboxType;
        resp = await this.$store.dispatch(
          'contactConversations/create',
          contactItem
        );
      }
      return resp;
    },
  },
};
</script>
