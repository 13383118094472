<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('FEEDBACK_MESSAGE.ADD.TITLE') }}
    </woot-button>

    <!-- List Agents -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('FEEDBACK_MESSAGE.LOADING')"
        />
        <div v-else>
          <p v-if="!feedbackList.length">
            {{ $t('FEEDBACK_MESSAGE.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <thead>
              <th
                v-for="tableHeader in $t('FEEDBACK_MESSAGE.LIST.TABLE_HEADER')"
                :key="tableHeader"
                class="item"
              >
                {{ tableHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(feedback, index) in feedbackList" :key="feedback.id">
                <!-- Gravtar Image -->
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ feedback.user.name }}
                  </span>
                </td>
                <!-- Agent Name + Email -->
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ feedback.name }}
                  </span>
                </td>
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ truncateText(feedback.endpoint, 40) }}
                  </span>
                </td>
                <!-- Actions -->
                <td class="fixed-width">
                  <div class="button-wrapper">
                    <woot-button
                      v-tooltip.top="$t('FEEDBACK_MESSAGE.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      class-names="grey-btn"
                      @click="openEditPopup(feedback)"
                    />
                    <woot-button
                      v-tooltip.top="$t('FEEDBACK_MESSAGE.DELETE.BUTTON_TEXT')"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[feedback.id]"
                      @click="openDeletePopup(feedback, index)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-[34%]">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('FEEDBACK_MESSAGE.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-feedback :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-feedback
        v-if="showEditPopup"
        :id="currentFeedback.id"
        :name="currentFeedback.name"
        :endpoint="currentFeedback.endpoint"
        :user-id="currentFeedback.user_id"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('FEEDBACK_MESSAGE.DELETE.CONFIRM.TITLE')"
      :message="$t('FEEDBACK_MESSAGE.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import EditFeedback from './EditFeedback.vue';
import AddFeedback from './AddFeedback.vue';
export default {
  components: {
    AddFeedback,
    EditFeedback,
  },
  mixins: [globalConfigMixin],
  data() {
      return {
          loading: {},
          showAddPopup: false,
          showDeletePopup: false,
          showEditPopup: false,
          feedbackAPI: {
              message: '',
          },
          currentFeedback: {},
      };
  },
  computed: {
    ...mapGetters({
      feedbackList: 'feedbacks/getFeedbacks',
      uiFlags: 'feedbacks/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
    deleteMessage() {
      return ` ${this.currentFeedback.name}?`;
    },
    deleteConfirmText() {
      return `${this.$t('FEEDBACK_MESSAGE.DELETE.CONFIRM.YES')} ${
        this.currentFeedback.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('FEEDBACK_MESSAGE.DELETE.CONFIRM.NO')} ${
        this.currentFeedback.name
      }`;
    },
  },
  mounted() {
    this.$store.dispatch('feedbacks/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    // Delete Function
    openDeletePopup(feedback) {
      this.showDeletePopup = true;
      this.currentFeedback = feedback;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentFeedback.id] = true;
      this.closeDeletePopup();
      this.deleteFeedback(this.currentFeedback.id);
    },
    // Edit Function
    openEditPopup(feedback) {
      this.showEditPopup = true;
      this.currentFeedback = feedback;
    },
    async deleteFeedback(id) {
      try {
        await this.$store.dispatch('feedbacks/delete', id);
        this.showAlert(this.$t('FEEDBACK_MESSAGE.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('FEEDBACK_MESSAGE.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected feedback
      this.loading[this.currentFeedback.id] = false;
      this.currentFeedback = {};
      // Show message
      this.feedbackAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
  },
};
</script>
<style scoped lang="scss">
.fixed-width {
  width: 150px; /* You can adjust the width to your desired value */
}
</style>
