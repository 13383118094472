<template>
  <div class="column content-box">
    <div class="medium-12 small-12 columns margin_top_1">
      <!-- Add a v-if condition to check if currentIframe.url is available -->
      <div v-if="showLoader" class="iframe-loader">
        <spinner />
        <span>{{ $t('IFRAME_CREATOR.LOADING_MESSAGE') }}</span>
      </div>
      <iframe
        v-if="currentIframe"
        :src="constructIframeUrl(currentIframe.url, params)"
        allow="clipboard-read; clipboard-write"
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
        style="height: calc(100vh - 66px); border: none;"
        width="100%"
        height="100%"
        @load="stopLoader"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      currentIframe: {},
      iframeList: [],
      showLoader: true,
      user_id: null,
      params: {
        userId: null,
        inboxId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      iframeCreatorList: 'iframeCreators/getIframeCreators',
    }),
  },
  watch: {
    '$route.params.id': {
      immediate: true, // Run the handler immediately on component mount
      handler(newId, oldId) {
        this.iframeList = this.iframeCreatorList;
        this.currentIframe = this.iframeList.find(iframe => iframe.id == newId);
        this.params.inboxId = this.currentIframe?.inbox_id;
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('iframeCreators/get');
    this.params.userId = await this.$store.getters.getCurrentUserID;
    if (this.$route.params.id) {
      this.iframeList = this.iframeCreatorList;
      this.currentIframe = this.iframeList.find(
        iframe => iframe.id == this.$route.params.id
      );
      this.params.inboxId = this.currentIframe?.inbox_id;
    }
  },
  methods: {
    constructIframeUrl(url, params) {
      // Parse the existing query parameters from the URL
      const urlParts = url.split('?');
      const baseUrl = urlParts[0];
      const existingParams = urlParts[1] || '';

      // Construct the URL with the parameters
      const queryString = Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

      // Combine the existing parameters and new parameters
      const finalQueryString = existingParams
        ? `${existingParams}&${queryString}`
        : queryString;

      return `${baseUrl}?${finalQueryString}`;
    },
    stopLoader() {
      this.showLoader = false;
    },
  },
};
</script>

<style scoped lang="scss">
.iframe-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}
</style>
