<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />
      <form class="w-full" @submit.prevent="editAgent()">
        <div class="w-full">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="w-full">
          <label>
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_CATEGORY.LABEL') }}
            <select v-model="category">
              <option
                v-for="cat in agentCategories"
                :key="cat.name"
                :value="cat.name"
              >
                {{ cat.label }}
              </option>
            </select>
          </label>
        </div>

        <label
          v-if="agentType === 'administrator' || agentType === 'manager'"
          class="medium-12 columns"
        >
          <div class="enter-to-send--checkbox">
            <input
              id="enableAutoAssignment"
              v-model="is_restricted"
              type="checkbox"
              @click="handleRestriction"
            />
            <label for="enableAutoAssignment">
              {{ $t('AGENT_MGMT.EDIT.FORM.IS_RESTRICTED.LABEL') }}
            </label>
          </div>
        </label>

        <div v-if="is_restricted === true" class="medium-12 columns">
          <label>
            {{ $t('AGENT_MGMT.ADD.FORM.ADD_AGENTS_ON_INBOX.LABEL') }}
            <multiselect
              v-model="selectedInboxes"
              :options="allInboxes"
              track-by="id"
              label="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :placeholder="
                $t('AGENT_MGMT.ADD.FORM.ADD_AGENTS_ON_INBOX.PLACEHOLDER')
              "
              @select="$v.selectedInboxes.$touch"
            />
          </label>
        </div>

        <div class="medium-12 modal-footer">
          <div class="medium-6 columns">
            <woot-submit-button
              :disabled="
                $v.agentType.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
          <div class="w-[50%] text-right">
            <woot-button
              icon="lock-closed"
              variant="clear"
              @click.prevent="resetUserPassword"
            >
              {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
// import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';
import { resetPassword } from '../../../../../v3/api/auth';

// eslint-disable-next-line no-unused-vars
const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    restricted: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      required: true,
    },
    category: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      agentName: this.name,
      agentType: this.type,
      agentCredentials: {
        email: this.email,
      },
      show: true,
      inboxes: [],
      is_restricted: this.restricted,
      selectedInboxes: [],
    };
  },
  validations: {
    selectedInboxes: {
      isEmpty() {
        return !!this.selectedInboxes.length;
      },
    },
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentType: {
      required,
    },
    agentAvailability: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },

    roles() {
      if (this.currentUserRole === 'manager') {
        return [
          {
            name: 'agent',
            label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
          },
        ];
      }
      return [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
        {
          name: 'manager',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.MANAGER'),
        },
      ];
    },
    agentCategories() {
      return [
        {
          name: 'adcomp',
          label: this.$t('AGENT_MGMT.AGENT_CATEGORY.ADCOM'),
        },
        {
          name: 'city',
          label: this.$t('AGENT_MGMT.AGENT_CATEGORY.CITY'),
        },
      ];
    },

    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      allInboxes: 'inboxes/getAllInboxes',
      currentUserRole: 'getCurrentRole',
    }),
  },
  mounted() {
    this.fetchUserInboxes();
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async editAgent() {
      const selectedInboxes = this.selectedInboxes.map(el => el.id);
      try {
        await this.$store.dispatch('agents/update', {
          id: this.id,
          name: this.agentName,
          role: this.agentType,
          category: this.category,
          restricted: this.agentType === 'agent' ? false : this.is_restricted,
          selected_inbox: selectedInboxes,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async resetUserPassword() {
      try {
        await resetPassword(this.agentCredentials);
        // await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
    handleRestriction() {
      this.is_restricted = !this.is_restricted;
    },
    async fetchUserInboxes() {
      try {
        await this.$store.dispatch('inboxes/getAccountInboxes');
        if (this.restricted) {
          this.selectedInboxes = await this.$store.dispatch(
            'inboxes/limitedInboxes',
            this.id
          );
        }
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
