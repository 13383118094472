<template>
    <woot-modal :show.sync="show" :on-close="onClose">
        <div class="h-auto overflow-auto flex flex-col">
            <woot-modal-header
                    :header-title="$t('IFRAME_CREATOR.ADD.TITLE')"
                    :header-content="$t('IFRAME_CREATOR.ADD.DESC')"
            />
            <form class="flex flex-col w-full items-start" @submit.prevent="addIframeCreator()">
                <div class="w-full">
                    <label :class="{ error: $v.iframeTitle.$error }">
                        {{ $t('IFRAME_CREATOR.ADD.FORM.TITLE.LABEL') }}
                        <input
                                v-model.trim="iframeTitle"
                                type="text"
                                :placeholder="$t('IFRAME_CREATOR.ADD.FORM.TITLE.PLACEHOLDER')"
                                @input="$v.iframeTitle.$touch"
                        />
                    </label>
                </div>
                <div class="w-full">
                    <label :class="{ error: $v.iframeUrl.$error }">
                        {{ $t('IFRAME_CREATOR.ADD.FORM.URL.LABEL') }}
                        <input
                                v-model.trim="iframeUrl"
                                type="text"
                                :placeholder="$t('IFRAME_CREATOR.ADD.FORM.URL.PLACEHOLDER')"
                                @input="$v.iframeUrl.$touch"
                        />
                    </label>
                </div>
                <div class="w-full">
                    <label :class="{ error: $v.iframeStatus.$error }">
                        {{ $t('IFRAME_CREATOR.ADD.FORM.STATUS.LABEL') }}
                        <select v-model="iframeStatus">
                            <option v-for="option in iframeOptions" :key="option.label" :value="option.name">
                                {{ option.label }}
                            </option>
                        </select>
                        <span v-if="$v.iframeStatus.$error" class="message">
                          {{ $t('IFRAME_CREATOR.ADD.FORM.STATUS.ERROR') }}
                        </span>
                    </label>
                </div>
                <div class="w-full">
                    <label>
                        {{ $t('IFRAME_CREATOR.ADD.FORM.ADD_INBOX_ON_IFRAME.LABEL') }}
                        <select v-model="inbox">
                          <option> All </option>
                          <option v-for="box in inboxesList" :key="box.id" :value="box.id">
                              {{ box.name }}
                          </option>
                        </select>
                    </label>
                </div>
                <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
                    <div class="w-full">
                        <woot-submit-button
                                :disabled="
                $v.iframeUrl.$invalid ||
                  $v.iframeTitle.$invalid ||
                  $v.iframeStatus.$invalid ||
                  $v.inbox.$invalid ||
                  uiFlags.isCreating
              "
                                :button-text="$t('IFRAME_CREATOR.ADD.FORM.SUBMIT')"
                                :loading="uiFlags.isCreating"
                        />
                        <button class="button clear" @click.prevent="onClose">
                            {{ $t('IFRAME_CREATOR.ADD.CANCEL_BUTTON_TEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    props: {
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            iframeTitle: '',
            iframeUrl: '',
            iframeStatus: false,
            vertical: 'bottom',
            horizontal: 'center',
            show: true,
            inbox: {},
        };
    },
    computed: {
        ...mapGetters({
            uiFlags: 'agents/getUIFlags',
            inboxesList: 'inboxes/getInboxes',
            currentUserRole: 'getCurrentRole',
        }),
        iframeOptions() {
            return  [
                {
                    name: 'enable',
                    label: this.$t('IFRAME_CREATOR.ADD.FORM.STATUS.ENABLED_LABEL'),
                },
                {
                    name: 'disable',
                    label: this.$t('IFRAME_CREATOR.ADD.FORM.STATUS.DISABLED_LABEL'),
                }
            ]
        },
    },
    validations: {
        iframeTitle: {
            required,
            minLength: minLength(3),
        },
        iframeUrl: {
            required,
        },
        iframeStatus: {
            required,
        },
        inbox: {
            required,
        }
    },

    async mounted() {
        await this.$store.dispatch('inboxes/get');
    },

    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async addIframeCreator() {
            try {
                await this.$store.dispatch('iframeCreators/create', {
                    title: this.iframeTitle,
                    status: this.iframeStatus === 'enable',
                    url: this.iframeUrl,
                    inbox_id: this.inbox,
                    allow_all: this.inbox === 'All',
                });
                this.showAlert(this.$t('IFRAME_CREATOR.ADD.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                const {
                    response: { data: { error: errorResponse = '' } = {} } = {},
                } = error;
                let errorMessage = '';
                if (error.response?.status === 422) {
                    errorMessage = this.$t('IFRAME_CREATOR.ADD.API.EXIST_MESSAGE');
                } else {
                    errorMessage = this.$t('IFRAME_CREATOR.ADD.API.ERROR_MESSAGE');
                }
                this.showAlert(errorResponse || errorMessage);
            }
        },
    },
};
</script>
