var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('AUTOMATION.EDIT.TITLE')}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col modal-content"},[(_vm.automation)?_c('div',{staticClass:"w-full"},[_c('woot-input',{class:{ error: _vm.$v.automation.name.$error },attrs:{"label":_vm.$t('AUTOMATION.ADD.FORM.NAME.LABEL'),"type":"text","error":_vm.$v.automation.name.$error
            ? _vm.$t('AUTOMATION.ADD.FORM.NAME.ERROR')
            : '',"placeholder":_vm.$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')},on:{"blur":_vm.$v.automation.name.$touch},model:{value:(_vm.automation.name),callback:function ($$v) {_vm.$set(_vm.automation, "name", $$v)},expression:"automation.name"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.automation.description.$error },attrs:{"label":_vm.$t('AUTOMATION.ADD.FORM.DESC.LABEL'),"type":"text","error":_vm.$v.automation.description.$error
            ? _vm.$t('AUTOMATION.ADD.FORM.DESC.ERROR')
            : '',"placeholder":_vm.$t('AUTOMATION.ADD.FORM.DESC.PLACEHOLDER')},on:{"blur":_vm.$v.automation.description.$touch},model:{value:(_vm.automation.description),callback:function ($$v) {_vm.$set(_vm.automation, "description", $$v)},expression:"automation.description"}}),_vm._v(" "),_c('div',{staticClass:"event_wrapper"},[_c('label',{class:{ error: _vm.$v.automation.event_name.$error }},[_vm._v("\n          "+_vm._s(_vm.$t('AUTOMATION.ADD.FORM.EVENT.LABEL'))+"\n          "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.automation.event_name),expression:"automation.event_name"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.automation, "event_name", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.onEventChange()}]}},_vm._l((_vm.automationRuleEvents),function(event){return _c('option',{key:event.key,domProps:{"value":event.key}},[_vm._v("\n              "+_vm._s(event.value)+"\n            ")])}),0),_vm._v(" "),(_vm.$v.automation.event_name.$error)?_c('span',{staticClass:"message"},[_vm._v("\n            "+_vm._s(_vm.$t('AUTOMATION.ADD.FORM.EVENT.ERROR'))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('section',[_c('label',[_vm._v("\n          "+_vm._s(_vm.$t('AUTOMATION.ADD.FORM.CONDITIONS.LABEL'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-full p-4 bg-slate-25 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700 mb-4"},[_vm._l((_vm.automation.conditions),function(condition,i){return _c('filter-input-box',{key:i,attrs:{"filter-attributes":_vm.getAttributes(_vm.automation.event_name),"input-type":_vm.getInputType(_vm.automation.conditions[i].attribute_key),"operators":_vm.getOperators(_vm.automation.conditions[i].attribute_key),"dropdown-values":_vm.getConditionDropdownValues(
                _vm.automation.conditions[i].attribute_key
              ),"custom-attribute-type":_vm.getCustomAttributeType(_vm.automation.conditions[i].attribute_key),"show-query-operator":i !== _vm.automation.conditions.length - 1,"v":_vm.$v.automation.conditions.$each[i]},on:{"resetFilter":function($event){return _vm.resetFilter(i, _vm.automation.conditions[i])},"removeFilter":function($event){return _vm.removeFilter(i)}},model:{value:(_vm.automation.conditions[i]),callback:function ($$v) {_vm.$set(_vm.automation.conditions, i, $$v)},expression:"automation.conditions[i]"}})}),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('woot-button',{attrs:{"icon":"add","color-scheme":"success","variant":"smooth","size":"small"},on:{"click":_vm.appendNewCondition}},[_vm._v("\n              "+_vm._s(_vm.$t('AUTOMATION.ADD.CONDITION_BUTTON_LABEL'))+"\n            ")])],1)],2)]),_vm._v(" "),_c('section',[_c('label',[_vm._v("\n          "+_vm._s(_vm.$t('AUTOMATION.ADD.FORM.ACTIONS.LABEL'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-full p-4 bg-slate-25 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700 mb-4"},[_vm._l((_vm.automation.actions),function(action,i){return _c('automation-action-input',{key:i,attrs:{"action-types":_vm.automation.event_name === 'conversation_transfer'
                ? _vm.automationTransferActionTypes
                : _vm.automationActionTypes,"dropdown-values":_vm.getActionDropdownValues(action.action_name),"show-action-input":_vm.showActionInput(action.action_name),"v":_vm.$v.automation.actions.$each[i],"initial-file-name":_vm.getFileName(action, _vm.automation.files)},on:{"resetAction":function($event){return _vm.resetAction(i)},"removeAction":function($event){return _vm.removeAction(i)}},model:{value:(_vm.automation.actions[i]),callback:function ($$v) {_vm.$set(_vm.automation.actions, i, $$v)},expression:"automation.actions[i]"}})}),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('woot-button',{attrs:{"icon":"add","color-scheme":"success","variant":"smooth","size":"small"},on:{"click":_vm.appendNewAction}},[_vm._v("\n              "+_vm._s(_vm.$t('AUTOMATION.ADD.ACTION_BUTTON_LABEL'))+"\n            ")])],1)],2)]),_vm._v(" "),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-end gap-2 py-2 px-0 w-full"},[_c('woot-button',{staticClass:"button",attrs:{"variant":"clear"},on:{"click":function($event){$event.preventDefault();return _vm.onClose.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(_vm.$t('AUTOMATION.EDIT.CANCEL_BUTTON_TEXT'))+"\n          ")]),_vm._v(" "),_c('woot-button',{on:{"click":_vm.submitAutomation}},[_vm._v("\n            "+_vm._s(_vm.$t('AUTOMATION.EDIT.SUBMIT'))+"\n          ")])],1)])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }